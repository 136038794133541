import React, { useEffect } from "react";
import {
  Route,
  Redirect,
  Switch,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles/cake.css";
import "./styles/neon.css";
import "./styles/style.css";
import "./styles/chat.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Col from "react-bootstrap/Col";
//loading components
import Navi from "./components/Nav";
import Overview from "./components/overview";
import Abis from "./components/abilities";
import Forces from "./components/forces";
import Inventory from "./components/inventory_3";
import Login from "./components/login";
import Blackjack from "./components/casino/blackjack/Blackjack";
import Roulette from "./components/casino/roulette/roulette";
import Apartment from "./components/city/apartment";
import Bar from "./components/city/bar";
import Casino from "./components/casino/casino";
import Layer from "./components/city/layer";
import Layer2 from "./components/city/layer2";
import Alliance from "./components/alliance/alliance";
import Raid from "./components/alliance/raid";
import All from "./components/alliance/all";
import View from "./components/alliance/view";
import Arena from "./components/city/arena";
import City from "./components/city/overview";

import Messages from "./components/messages/index";

import Preferences from "./components/pref/preferences";
import Pada from "./components/pref/pada";

import Stats from "./components/stats/stats";
import Players from "./components/stats/player";

import Elo from "./components/events/elo";

import Chat from "./components/chat";

import Quest from "./components/quest/index";
import Puzzle from "./components/puzzle/App";

import { Row } from "react-bootstrap";
import Account from "./components/pref/account";
import Workshop from "./components/city/workshop";
import Trade from "./components/city/trade";
import Crafting from "./components/character/crafting";
import Register from "./components/register/register";
import Create from "./components/register/create";
import Bugs from "./components/bugs/bugs";
import BugsReport from "./components/bugs/report";
import Admin from "./components/admin/admin";
import slot from "./components/casino/slot/slot";
import Temple from "./components/city/temple";
import AlliResearch from "./components/alliance/research";
import Dquest from "./components/quest/dquest";
import Checks from "./components/checks/checks";

const App = () => {
  const isAuth = useSelector((state) => state.user.isAuth);

  return (
    <>
      <Router>
        {
          //Wichtig outside of container-fluid wegen padding
          isAuth !== false && <Navi />
        }
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />

          {isAuth !== false && (
            <Checks>
              <div className="container-fluid">
                <Row>
                  <Col md="9">
                    <Route path="/create" component={Create} />

                    <Route path="/overview" component={Overview} />
                    <Route path="/abilities" component={Abis} />
                    <Route path="/forces" component={Forces} />
                    <Route path="/inventory" component={Inventory} />
                    <Route path="/craft" component={Crafting} />

                    <Route path="/casino" component={Casino} />
                    <Route path="/bj" component={Blackjack} />
                    <Route path="/roulette" component={Roulette} />
                    <Route path="/slot" component={slot} />

                    <Route path="/apartment" component={Apartment} />
                    <Route path="/bar" component={Bar} />
                    <Route path="/layer" component={Layer} />
                    <Route path="/layer2" component={Layer2} />
                    <Route path="/city" component={City} />
                    <Route path="/workshop" component={Workshop} />
                    <Route path="/trade" component={Trade} />
                    <Route path="/temple" component={Temple} />

                    <Route exact path="/alliance" component={Alliance} />
                    <Route path="/alliance/raid" component={Raid} />
                    <Route path="/alliance/all" component={All} />
                    <Route path="/alliance/view" component={View} />
                    <Route path="/alliance/research" component={AlliResearch} />

                    <Route path="/arena" component={Arena} />

                    <Route path="/messages" component={Messages} />

                    <Route path="/pref" component={Preferences} />
                    <Route path="/pada" component={Pada} />
                    <Route path="/account" component={Account} />

                    <Route path="/bugs" exact component={Bugs} />
                    <Route path="/bugs/report" component={BugsReport} />

                    <Route path="/statistics" exact component={Stats} />
                    <Route path="/statistics/players" component={Players} />

                    <Route path="/events/rank" component={Elo} />

                    <Route path="/quest" component={Quest} />
                    <Route path="/dquest" component={Dquest} />
                    <Route path="/puzzle" component={Puzzle} />

                    <Route path="/admin" component={Admin} />
                  </Col>
                  <Col md={"3"}>
                    <Chat />
                  </Col>
                </Row>
              </div>
            </Checks>
          )}

          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
