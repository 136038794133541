export const FETCH_STATS = "FETCH_STATS";
export const FETCH_CHAR = "FETCH_CHAR";
export const FETCH_SIDE = "FETCH_SIDE";
export const FETCH_DATA = "FETCH_DATA";
export const FETCH_MASTER = "FETCH_MASTER";
export const FETCH_INV = "FETCH_INV";
export const FETCH_EQP = "FETCH_EQP";
export const IS_AUTH = "IS_AUTH";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const NEW_TRADE = "NEW_TRADE";
