import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import "./test.css";

const TestR = (props) => {
  return (
    <div>
      <Row>
        <Col
          style={{
            display: "grid",
            border: "8px solid black",
            borderRightStyle: "none",
          }}
          xs="1"
          className="hover-number text-center p-1"
        >
          <Badge
            style={{ fontSize: "150%" }}
            className="d-flex justify-content-center align-items-center"
            pill
            variant="success"
          >
            0
          </Badge>
        </Col>
        <Col className="p-0 gridLB">
          {/*rand oben*/}
          <>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
            <div className="bg-dark"></div>
          </>
          {/*erste zeile*/}
          <>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                3
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                6
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                9
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                12
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                15
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                18
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                21
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                24
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                27
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                30
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                33
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                36
              </Badge>
            </div>
            <div className="bg-dark"></div>
          </>
          {/*erste zwischenzeile*/}
          <>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="bg-dark"></div>
          </>
          {/*zweite zeile*/}
          <>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                2
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                5
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                8
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                11
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                14
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                17
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                20
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                23
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                26
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                29
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                32
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                35
              </Badge>
            </div>
            <div className="bg-dark"></div>
          </>
          {/*zweite zwischenzeile*/}
          <>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="bg-dark"></div>
          </>
          {/*dritte zeile*/}
          <>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                1
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                4
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                7
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                10
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                13
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                16
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                19
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                22
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                25
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                28
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="dark"
              >
                31
              </Badge>
            </div>
            <div className="hover"></div>
            <div
              style={{ display: "grid" }}
              className="p-1 hover-number text-center"
            >
              <Badge
                style={{ fontSize: "150%" }}
                className="d-flex justify-content-center align-items-center"
                pill
                variant="danger"
              >
                34
              </Badge>
            </div>
            <div className="bg-dark"></div>
          </>
          {/*dritte zwischenzeile*/}
          <>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="hover"></div>
            <div className="bg-dark"></div>
          </>
        </Col>
        <Col xs="1">
          <Row style={{ minHeight: "60px" }}>
            <Col>2 to 1</Col>
          </Row>
          <Row style={{ minHeight: "60px" }}>
            <Col>2 to 1</Col>
          </Row>
          <Row style={{ minHeight: "60px" }}>
            <Col>2 to 1</Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ minHeight: "60px" }}>
        <Col xs="1"></Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
          }}
          className="hover-number p-0 d-flex justify-content-center align-items-center"
        >
          1 - 12
        </Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
            borderLeftStyle: "none",
          }}
          className="hover-number p-0 d-flex justify-content-center align-items-center"
        >
          13 - 24
        </Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
            borderLeftStyle: "none",
          }}
          className="hover-number p-0 d-flex justify-content-center align-items-center"
        >
          25 - 36
        </Col>
        <Col className="p-0" xs="1"></Col>
      </Row>
      <Row style={{ minHeight: "60px" }}>
        <Col xs="1"></Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
          }}
          className="p-0"
        >
          1-18
        </Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
            borderLeftStyle: "none",
          }}
          className="p-0"
        >
          Odd
        </Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
            borderLeftStyle: "none",
          }}
          className="p-0"
        >
          Red
        </Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
            borderLeftStyle: "none",
          }}
          className="p-0"
        >
          Black
        </Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
            borderLeftStyle: "none",
          }}
          className="p-0"
        >
          Even
        </Col>
        <Col
          style={{
            border: "8px solid black",
            borderTopStyle: "none",
            borderLeftStyle: "none",
          }}
          className="p-0"
        >
          19-36
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};

export default TestR;
