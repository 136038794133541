import React from "react";

const slot = () => {
  const {
    SlotMachine,
    SlotSymbol,
  } = require("../../../../node_modules/slot-machine/src/index");

  const cherry = new SlotSymbol("cherry", {
    display: "🍒",
    points: 10,
    weight: 100,
  });

  const money = new SlotSymbol("money", {
    display: "💰",
    points: 100,
    weight: 50,
  });

  const wild = new SlotSymbol("wild", {
    display: "❔",
    points: 10,
    weight: 50,
    wildcard: true,
  });

  const machine = new SlotMachine(3, [cherry, money, wild]);
  const results = machine.play();

  console.log(results.visualize());
  return <></>;
};

export default slot;
