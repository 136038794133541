import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

import store from "./redux/store";
import { setBaseUrl } from "./tools/fetch";

window.store = store;

//setBaseUrl("http://localhost/qyr/rest-api");
//setBaseUrl("https://starwarslegends.de/rest-api");
setBaseUrl("https://test.starwarslegends.de/rest-api");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
