import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GET } from "../../tools/fetch";
import { fetchAllianceData } from "../../redux/actions/allianceActions";
import { characterState__setOverviewData } from "../../redux/actions/characterActions";
import { firestore } from "../../tools/firebase";
import { Redirect } from "react-router-dom";

const Checks = ({ children, roles, ...props }) => {
  const userId = useSelector((state) => state.user.userId);
  const username = useSelector((state) => state.user.username);
  const userid = useSelector((state) => state.user.userId);
  const dispatch = useDispatch();

  const checkTrades = async () => {
    try {
      const requestsDB = await GET("/trade/requests");
      if (requestsDB.requests.length > 0) {
        if (
          requestsDB.requests[0].offerer !== userId &&
          requestsDB.requests[0].status !== 3
        ) {
          dispatch({ type: "NEW_TRADE", payload: true });
        } else {
          dispatch({ type: "NEW_TRADE", payload: false });
        }
      }
    } catch (error) {
    } finally {
    }
  };
  const checkRaids = async () => {
    try {
      const responseAlli = await GET("/alliances");
      if (responseAlli) {
        dispatch(fetchAllianceData(responseAlli));
      }
    } catch (error) {
    } finally {
    }
  };

  const checkPM = () => {
    if (username) {
      const museums = firestore
        .collectionGroup("messages")
        .where("seen", "==", 0)
        .where("receiver", "==", username);
      museums.get().then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          dispatch({ type: "NEW_MESSAGE", payload: true });
        } else {
          dispatch({ type: "NEW_MESSAGE", payload: false });
        }
      });
      return () => {
        museums();
      };
    }
  };

  const loadData = async () => {
    try {
      const response = await GET("/character/overview");
      if (response) {
        dispatch(characterState__setOverviewData(response));
      }
    } catch (e) {
      return;
    }
  };

  //für Skillpoints
  loadData();
  //PM
  checkPM();
  //Tradeoffers
  checkTrades();
  //offene raids
  checkRaids();

  return <div>{children}</div>;
};

export default Checks;
